import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Gallery from '../components/Gallery';
import Contact from '../components/Contact';
import Helmet from 'react-helmet';
import get from 'lodash/get';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

class WorkTypeTemplate extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const workType = this.props.data.contentfulWorkType;
    const { description } = workType.childContentfulWorkTypeDescriptionRichTextNode;
    return (
      <Layout location={this.props.location}>
        <Helmet title={`${workType.workTitle} | ${siteTitle}`} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-10 section text-center">
              <h1 className="section--title work-title">{workType.workTitle}</h1>
              <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(description)) }} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Gallery images={workType.images} workTitle={workType.workTitle} />
            </div>
          </div>
        </div>
        <Contact />
      </Layout>
    );
  }
}

export default WorkTypeTemplate;

export const pageQuery = graphql`
  query WorkTypeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulWorkType(slug: { eq: $slug }) {
      workTitle
      images {
        description
        file {
          url
        }
      }
      childContentfulWorkTypeDescriptionRichTextNode {
        description
      }
    }
  }
`;
